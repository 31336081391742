<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
              <div class="form">
                <section class="form-section">
                  <FormRow>
                    <template v-slot:label>CPMカード払い専用画面のサインインリンク：</template>
                    <template v-slot:content>
                      <span>
                        <span class="pr-10">{{ cpmUrl }}</span>
                        <a :href="cpmUrl" target="_blank">
                          <i class="aikon aikon-external text-blue"></i>
                        </a>
                      </span>
                    </template>
                  </FormRow>
                </section>
              </div>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    :checkedKey="checkedKey"
                    :dataTable="dataTable"
                    :labels="labels"
                    :count="cpmUserCount"
                    @select-items="handleSelectedItems"
                    ref="table"
                  ></TableWithCheckbox>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <ActionButton class="btn btn-red" :handle-submit="showDeleteModal" button-text="削除" />
          </li>
          <li class="listGrid-item pos-end" v-if="cpmUserCount < 10">
            <router-link
              class="btn btn-main"
              :to="{ name: 'AccountUserCpmRegister' }"
            >
              新規登録
            </router-link>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Modal @close="closeModal('deletedModal')" v-if="deletedModal">
      <template v-slot:headline>削除</template>
      <template v-slot:body>
        <p class="description text-align-center">
          対象のアカウントを削除しますか？
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button
              class="btn btn-lg btn-white"
              type="button"
              @click="closeModal('deletedModal')"
            >
              キャンセル
            </button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-red"
              :handle-submit="deleteCpmUser"
              button-text="削除する"
            />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { endline } from '@/helpers/formatData';
//mixin
import modal from '@/mixins/plugin/modal';
import checkall from '@/mixins/plugin/checkall';
import cpmUser from '@/mixins/module/cpmUser';
//component
import FormRow from '@/components/FormRow.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'AccountUserCpm',
  data: function() {
    return {
      pageName: 'アカウント設定',
      labels: [
        { isCheckbox: true },
        { key: 'name', name: 'ユーザー名' },
        { key: 'cpmUserId', name: 'ID' },
        { key: 'id', name: 'id', notShow: true },
      ],
      checkedKey: 'id',
      selectedItems: {
        allFlag: false,
        ids: [],
      },
      deletedModal: false,
    };
  },
  computed: {
    ...mapGetters({
      cpmUserList: 'settingUserCpm/cpmUserList',
      cpmUserCount: 'settingUserCpm/cpmUserCount',
      infor: 'auth/infor',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
      parentEventDetail: 'event/parentEventDetail',
    }),
    cpmUrl() {
      return this.isGmoOrOfficeLoginStore ? this.parentEventDetail?.cpmUrl : this.infor?.cpmUrl;
    },
    dataTable() {
      return this.cpmUserList.map((item) => {
        return {
          ...item,
          name: {
            content: endline(item?.nameSei + ' ' + item?.nameMei, 21),
            routeName: 'AccountUserCpmEdit',
            params: { id: item.id },
            isLink: true
          }
        };
      });
    },
  },
  components: {
    FormRow,
    TableWithCheckbox,
    Modal
  },
  mixins: [cpmUser, modal, checkall],
  methods: {
    showDeleteModal() {
      if (this.selectedItems.allFlag || this.selectedItems.ids.length > 0) {
        this.showModal('deletedModal');
      } else {
        this.$message.noSelectedItem();
      }
    },
    handleSelectedItems(nVal) {
      this.selectedItems = {
        allFlag: nVal.allFlag,
        ids: nVal.ids,
      };
    },
    async deleteCpmUser() {
      const res = await this.dispatchAction('settingUserCpm/deleteCpmUsers', { data: this.selectedItems });
      this.$refs.table.resetCheck();
      this.closeModal('deletedModal');
      if (res) {
        this.getCpmUsers();
        this.$store.dispatch('auth/getUserInfor');
        this.$message.deleted('account');
      }
    }
  },
  async mounted() {
    const loading = this.$loading.show();
    Promise.all([
      this.getCpmUsers()
    ]).finally(() => {
      this.$loading.clear(loading);
    })
  }
};
</script>
